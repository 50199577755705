// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE

import * as Cn from "re-classnames/src/Cn.bs.js";
import * as Api from "./Api.bs.js";
import * as Form from "./Form.bs.js";
import * as Block from "bs-platform/lib/es6/block.js";
import * as Curry from "bs-platform/lib/es6/curry.js";
import * as React from "react";
import * as Formality from "re-formality/src/Formality.bs.js";
import * as Formality__Debouncer from "re-formality/src/Formality__Debouncer.bs.js";
import * as Formality__ReactUpdate from "re-formality/src/Formality__ReactUpdate.bs.js";

var validators_email = {
  strategy: /* OnFirstSuccessOrFirstBlur */3,
  validate: (function (param) {
      var email = param.email;
      var emailRegex = /.*@.*\..+/;
      if (email === "") {
        return /* Error */Block.__(1, ["Email is required"]);
      } else if (emailRegex.test(email)) {
        return /* Ok */Block.__(0, [email]);
      } else {
        return /* Error */Block.__(1, ["Email is invalid"]);
      }
    }),
  validateAsync: Formality__Debouncer.make(700, (function (param) {
          var dispatch = param[1];
          var value = param[0];
          var validate = function (email) {
            var __x = Api.validateEmail(email);
            return __x.then((function (valid) {
                          if (valid) {
                            return Promise.resolve(/* Ok */Block.__(0, [email]));
                          } else {
                            return Promise.resolve(/* Error */Block.__(1, ["Email is already taken"]));
                          }
                        }));
          };
          return Formality.Async.validateAsync(value, validate, (function (res) {
                        return Curry._1(dispatch, /* ApplyAsyncResultForEmailField */Block.__(3, [
                                      value,
                                      res
                                    ]));
                      }));
        })),
  eq: (function (prim, prim$1) {
      return prim === prim$1;
    })
};

var validators_password = {
  strategy: /* OnFirstSuccessOrFirstBlur */3,
  validate: (function (param) {
      var password = param.password;
      if (password === "") {
        return /* Error */Block.__(1, ["Password is required"]);
      } else if (password.length < 4) {
        return /* Error */Block.__(1, [" " + (String(4) + "+ characters, please")]);
      } else {
        return /* Ok */Block.__(0, [password]);
      }
    })
};

var validators_passwordConfirmation = {
  strategy: /* OnFirstSuccessOrFirstBlur */3,
  validate: (function (param) {
      var passwordConfirmation = param.passwordConfirmation;
      if (passwordConfirmation === "") {
        return /* Error */Block.__(1, ["Confirmation is required"]);
      } else if (passwordConfirmation !== param.password) {
        return /* Error */Block.__(1, ["Password doesn't match"]);
      } else {
        return /* Ok */Block.__(0, [passwordConfirmation]);
      }
    })
};

var validators = {
  email: validators_email,
  password: validators_password,
  passwordConfirmation: validators_passwordConfirmation
};

function initialFieldsStatuses(_input) {
  return {
          email: /* Pristine */0,
          password: /* Pristine */0,
          passwordConfirmation: /* Pristine */0
        };
}

function initialState(input) {
  return {
          input: input,
          fieldsStatuses: {
            email: /* Pristine */0,
            password: /* Pristine */0,
            passwordConfirmation: /* Pristine */0
          },
          collectionsStatuses: /* () */0,
          formStatus: /* Editing */0,
          submissionStatus: /* NeverSubmitted */0
        };
}

function validateForm(input, validators, fieldsStatuses) {
  var match = fieldsStatuses.email;
  var tmp;
  tmp = typeof match === "number" ? /* `Result */[
      1025039613,
      Curry._1(validators.email.validate, input)
    ] : (
      match.tag ? /* `Validating */[
          -660729997,
          match[0]
        ] : /* `Result */[
          1025039613,
          match[0]
        ]
    );
  var match$1 = fieldsStatuses.password;
  var match_000 = match$1 ? match$1[0] : Curry._1(validators.password.validate, input);
  var match$2 = fieldsStatuses.passwordConfirmation;
  var match_000$1 = match$2 ? match$2[0] : Curry._1(validators.passwordConfirmation.validate, input);
  var emailResult = tmp;
  if (emailResult[0] >= 1025039613) {
    var emailResult$1 = emailResult[1];
    var emailResult$2;
    if (emailResult$1.tag) {
      emailResult$2 = emailResult$1;
    } else {
      var passwordResult = match_000;
      if (passwordResult.tag) {
        emailResult$2 = emailResult$1;
      } else {
        var passwordConfirmationResult = match_000$1;
        if (passwordConfirmationResult.tag) {
          emailResult$2 = emailResult$1;
        } else {
          return /* Valid */Block.__(0, [
                    /* output */{
                      email: emailResult$1[0],
                      password: passwordResult[0],
                      passwordConfirmation: passwordConfirmationResult[0]
                    },
                    /* fieldsStatuses */{
                      email: /* Dirty */Block.__(0, [
                          emailResult$1,
                          /* Shown */0
                        ]),
                      password: /* Dirty */[
                        passwordResult,
                        /* Shown */0
                      ],
                      passwordConfirmation: /* Dirty */[
                        passwordConfirmationResult,
                        /* Shown */0
                      ]
                    },
                    /* collectionsStatuses : () */0
                  ]);
        }
      }
    }
    return /* Invalid */Block.__(1, [
              /* fieldsStatuses */{
                email: /* Dirty */Block.__(0, [
                    emailResult$2,
                    /* Shown */0
                  ]),
                password: /* Dirty */[
                  match_000,
                  /* Shown */0
                ],
                passwordConfirmation: /* Dirty */[
                  match_000$1,
                  /* Shown */0
                ]
              },
              /* collectionsStatuses : () */0
            ]);
  } else {
    return /* Validating */Block.__(2, [
              /* fieldsStatuses */{
                email: emailResult[0] >= 1025039613 ? /* Dirty */Block.__(0, [
                      emailResult[1],
                      /* Shown */0
                    ]) : /* Validating */Block.__(1, [emailResult[1]]),
                password: /* Dirty */[
                  match_000,
                  /* Shown */0
                ],
                passwordConfirmation: /* Dirty */[
                  match_000$1,
                  /* Shown */0
                ]
              },
              /* collectionsStatuses : () */0
            ]);
  }
}

function useForm(initialInput, onSubmit) {
  var memoizedInitialState = React.useMemo((function () {
          return initialState(initialInput);
        }), [initialInput]);
  var match = Formality__ReactUpdate.useReducer(memoizedInitialState, (function (state, action) {
          if (typeof action === "number") {
            switch (action) {
              case /* BlurPasswordConfirmationField */0 :
                  var result = Formality.validateFieldOnBlurWithValidator(state.input, state.fieldsStatuses.passwordConfirmation, validators_passwordConfirmation, (function (status) {
                          var init = state.fieldsStatuses;
                          return {
                                  email: init.email,
                                  password: init.password,
                                  passwordConfirmation: status
                                };
                        }));
                  if (result !== undefined) {
                    return /* Update */Block.__(0, [{
                                input: state.input,
                                fieldsStatuses: result,
                                collectionsStatuses: state.collectionsStatuses,
                                formStatus: state.formStatus,
                                submissionStatus: state.submissionStatus
                              }]);
                  } else {
                    return /* NoUpdate */0;
                  }
              case /* BlurPasswordField */1 :
                  var result$1 = Formality.validateFieldOnBlurWithValidator(state.input, state.fieldsStatuses.password, validators_password, (function (status) {
                          var init = state.fieldsStatuses;
                          return {
                                  email: init.email,
                                  password: status,
                                  passwordConfirmation: init.passwordConfirmation
                                };
                        }));
                  if (result$1 !== undefined) {
                    return /* Update */Block.__(0, [{
                                input: state.input,
                                fieldsStatuses: result$1,
                                collectionsStatuses: state.collectionsStatuses,
                                formStatus: state.formStatus,
                                submissionStatus: state.submissionStatus
                              }]);
                  } else {
                    return /* NoUpdate */0;
                  }
              case /* BlurEmailField */2 :
                  var result$2 = Formality.Async.validateFieldOfStringTypeOnBlur(state.input, state.fieldsStatuses.email, validators_email, (function (status) {
                          var init = state.fieldsStatuses;
                          return {
                                  email: status,
                                  password: init.password,
                                  passwordConfirmation: init.passwordConfirmation
                                };
                        }));
                  if (result$2 !== undefined) {
                    var fieldsStatuses = result$2;
                    var match = fieldsStatuses.email;
                    if (typeof match !== "number" && match.tag) {
                      var value = match[0];
                      return /* UpdateWithSideEffects */Block.__(1, [
                                {
                                  input: state.input,
                                  fieldsStatuses: fieldsStatuses,
                                  collectionsStatuses: state.collectionsStatuses,
                                  formStatus: state.formStatus,
                                  submissionStatus: state.submissionStatus
                                },
                                (function (param) {
                                    return Curry._1(validators_email.validateAsync, /* tuple */[
                                                value,
                                                param.dispatch
                                              ]);
                                  })
                              ]);
                    }
                    return /* Update */Block.__(0, [{
                                input: state.input,
                                fieldsStatuses: fieldsStatuses,
                                collectionsStatuses: state.collectionsStatuses,
                                formStatus: state.formStatus,
                                submissionStatus: state.submissionStatus
                              }]);
                  } else {
                    return /* NoUpdate */0;
                  }
                  break;
              case /* Submit */3 :
                  var match$1 = state.formStatus;
                  if (typeof match$1 !== "number" && !match$1.tag) {
                    return /* NoUpdate */0;
                  }
                  var match$2 = validateForm(state.input, validators, state.fieldsStatuses);
                  switch (match$2.tag | 0) {
                    case /* Valid */0 :
                        var output = match$2[/* output */0];
                        var match$3 = state.formStatus;
                        var tmp;
                        tmp = typeof match$3 === "number" || match$3.tag !== /* SubmissionFailed */1 ? undefined : match$3[0];
                        return /* UpdateWithSideEffects */Block.__(1, [
                                  {
                                    input: state.input,
                                    fieldsStatuses: match$2[/* fieldsStatuses */1],
                                    collectionsStatuses: match$2[/* collectionsStatuses */2],
                                    formStatus: /* Submitting */Block.__(0, [tmp]),
                                    submissionStatus: /* AttemptedToSubmit */1
                                  },
                                  (function (param) {
                                      var dispatch = param.dispatch;
                                      return Curry._2(onSubmit, output, {
                                                  notifyOnSuccess: (function (input) {
                                                      return Curry._1(dispatch, /* SetSubmittedStatus */Block.__(4, [input]));
                                                    }),
                                                  notifyOnFailure: (function (error) {
                                                      return Curry._1(dispatch, /* SetSubmissionFailedStatus */Block.__(5, [error]));
                                                    }),
                                                  reset: (function (param) {
                                                      return Curry._1(dispatch, /* Reset */6);
                                                    }),
                                                  dismissSubmissionResult: (function (param) {
                                                      return Curry._1(dispatch, /* DismissSubmissionResult */5);
                                                    })
                                                });
                                    })
                                ]);
                    case /* Invalid */1 :
                        return /* Update */Block.__(0, [{
                                    input: state.input,
                                    fieldsStatuses: match$2[/* fieldsStatuses */0],
                                    collectionsStatuses: match$2[/* collectionsStatuses */1],
                                    formStatus: /* Editing */0,
                                    submissionStatus: /* AttemptedToSubmit */1
                                  }]);
                    case /* Validating */2 :
                        return /* Update */Block.__(0, [{
                                    input: state.input,
                                    fieldsStatuses: match$2[/* fieldsStatuses */0],
                                    collectionsStatuses: match$2[/* collectionsStatuses */1],
                                    formStatus: state.formStatus,
                                    submissionStatus: state.submissionStatus
                                  }]);
                    
                  }
                  break;
              case /* DismissSubmissionError */4 :
                  var match$4 = state.formStatus;
                  if (typeof match$4 === "number" || match$4.tag !== /* SubmissionFailed */1) {
                    return /* NoUpdate */0;
                  } else {
                    return /* Update */Block.__(0, [{
                                input: state.input,
                                fieldsStatuses: state.fieldsStatuses,
                                collectionsStatuses: state.collectionsStatuses,
                                formStatus: /* Editing */0,
                                submissionStatus: state.submissionStatus
                              }]);
                  }
              case /* DismissSubmissionResult */5 :
                  var match$5 = state.formStatus;
                  if (typeof match$5 === "number") {
                    if (match$5 === /* Editing */0) {
                      return /* NoUpdate */0;
                    }
                    
                  } else if (!match$5.tag) {
                    return /* NoUpdate */0;
                  }
                  return /* Update */Block.__(0, [{
                              input: state.input,
                              fieldsStatuses: state.fieldsStatuses,
                              collectionsStatuses: state.collectionsStatuses,
                              formStatus: /* Editing */0,
                              submissionStatus: state.submissionStatus
                            }]);
              case /* Reset */6 :
                  return /* Update */Block.__(0, [initialState(initialInput)]);
              
            }
          } else {
            switch (action.tag | 0) {
              case /* UpdatePasswordConfirmationField */0 :
                  var nextInput = Curry._1(action[0], state.input);
                  return /* Update */Block.__(0, [{
                              input: nextInput,
                              fieldsStatuses: Formality.validateFieldOnChangeWithValidator(nextInput, state.fieldsStatuses.passwordConfirmation, state.submissionStatus, validators_passwordConfirmation, (function (status) {
                                      var init = state.fieldsStatuses;
                                      return {
                                              email: init.email,
                                              password: init.password,
                                              passwordConfirmation: status
                                            };
                                    })),
                              collectionsStatuses: state.collectionsStatuses,
                              formStatus: state.formStatus,
                              submissionStatus: state.submissionStatus
                            }]);
              case /* UpdatePasswordField */1 :
                  var nextInput$1 = Curry._1(action[0], state.input);
                  var nextFieldsStatuses = {
                    contents: state.fieldsStatuses
                  };
                  var match$6 = Formality.validateDependentFieldOnChange(nextInput$1, nextFieldsStatuses.contents.passwordConfirmation, validators_passwordConfirmation, (function (status) {
                          var init = nextFieldsStatuses.contents;
                          return {
                                  email: init.email,
                                  password: init.password,
                                  passwordConfirmation: status
                                };
                        }));
                  if (match$6 !== undefined) {
                    nextFieldsStatuses.contents = match$6;
                  }
                  return /* Update */Block.__(0, [{
                              input: nextInput$1,
                              fieldsStatuses: Formality.validateFieldOnChangeWithValidator(nextInput$1, nextFieldsStatuses.contents.password, state.submissionStatus, validators_password, (function (status) {
                                      var init = nextFieldsStatuses.contents;
                                      return {
                                              email: init.email,
                                              password: status,
                                              passwordConfirmation: init.passwordConfirmation
                                            };
                                    })),
                              collectionsStatuses: state.collectionsStatuses,
                              formStatus: state.formStatus,
                              submissionStatus: state.submissionStatus
                            }]);
              case /* UpdateEmailField */2 :
                  var nextInput$2 = Curry._1(action[0], state.input);
                  var nextFieldsStatuses$1 = Formality.Async.validateFieldOfStringTypeOnChangeInOnChangeMode(nextInput$2, state.fieldsStatuses.email, state.submissionStatus, validators_email, (function (status) {
                          var init = state.fieldsStatuses;
                          return {
                                  email: status,
                                  password: init.password,
                                  passwordConfirmation: init.passwordConfirmation
                                };
                        }));
                  var match$7 = nextFieldsStatuses$1.email;
                  if (typeof match$7 !== "number" && match$7.tag) {
                    var value$1 = match$7[0];
                    return /* UpdateWithSideEffects */Block.__(1, [
                              {
                                input: nextInput$2,
                                fieldsStatuses: nextFieldsStatuses$1,
                                collectionsStatuses: state.collectionsStatuses,
                                formStatus: state.formStatus,
                                submissionStatus: state.submissionStatus
                              },
                              (function (param) {
                                  return Curry._1(validators_email.validateAsync, /* tuple */[
                                              value$1,
                                              param.dispatch
                                            ]);
                                })
                            ]);
                  }
                  return /* Update */Block.__(0, [{
                              input: nextInput$2,
                              fieldsStatuses: nextFieldsStatuses$1,
                              collectionsStatuses: state.collectionsStatuses,
                              formStatus: state.formStatus,
                              submissionStatus: state.submissionStatus
                            }]);
              case /* ApplyAsyncResultForEmailField */3 :
                  var validator = validators_email;
                  var match$8 = state.fieldsStatuses.email;
                  if (typeof match$8 === "number" || !(match$8.tag && Curry._2(validator.eq, match$8[0], action[0]))) {
                    return /* NoUpdate */0;
                  } else {
                    var init = state.fieldsStatuses;
                    return /* Update */Block.__(0, [{
                                input: state.input,
                                fieldsStatuses: {
                                  email: /* Dirty */Block.__(0, [
                                      action[1],
                                      /* Shown */0
                                    ]),
                                  password: init.password,
                                  passwordConfirmation: init.passwordConfirmation
                                },
                                collectionsStatuses: state.collectionsStatuses,
                                formStatus: state.formStatus,
                                submissionStatus: state.submissionStatus
                              }]);
                  }
              case /* SetSubmittedStatus */4 :
                  var input = action[0];
                  if (input !== undefined) {
                    return /* Update */Block.__(0, [{
                                input: input,
                                fieldsStatuses: {
                                  email: /* Pristine */0,
                                  password: /* Pristine */0,
                                  passwordConfirmation: /* Pristine */0
                                },
                                collectionsStatuses: state.collectionsStatuses,
                                formStatus: /* Submitted */1,
                                submissionStatus: state.submissionStatus
                              }]);
                  } else {
                    return /* Update */Block.__(0, [{
                                input: state.input,
                                fieldsStatuses: {
                                  email: /* Pristine */0,
                                  password: /* Pristine */0,
                                  passwordConfirmation: /* Pristine */0
                                },
                                collectionsStatuses: state.collectionsStatuses,
                                formStatus: /* Submitted */1,
                                submissionStatus: state.submissionStatus
                              }]);
                  }
              case /* SetSubmissionFailedStatus */5 :
                  return /* Update */Block.__(0, [{
                              input: state.input,
                              fieldsStatuses: state.fieldsStatuses,
                              collectionsStatuses: state.collectionsStatuses,
                              formStatus: /* SubmissionFailed */Block.__(1, [action[0]]),
                              submissionStatus: state.submissionStatus
                            }]);
              case /* MapSubmissionError */6 :
                  var map = action[0];
                  var match$9 = state.formStatus;
                  if (typeof match$9 === "number") {
                    return /* NoUpdate */0;
                  } else if (match$9.tag) {
                    return /* Update */Block.__(0, [{
                                input: state.input,
                                fieldsStatuses: state.fieldsStatuses,
                                collectionsStatuses: state.collectionsStatuses,
                                formStatus: /* SubmissionFailed */Block.__(1, [Curry._1(map, match$9[0])]),
                                submissionStatus: state.submissionStatus
                              }]);
                  } else {
                    var match$10 = match$9[0];
                    if (match$10 !== undefined) {
                      return /* Update */Block.__(0, [{
                                  input: state.input,
                                  fieldsStatuses: state.fieldsStatuses,
                                  collectionsStatuses: state.collectionsStatuses,
                                  formStatus: /* Submitting */Block.__(0, [Curry._1(map, match$10)]),
                                  submissionStatus: state.submissionStatus
                                }]);
                    } else {
                      return /* NoUpdate */0;
                    }
                  }
              
            }
          }
        }));
  var dispatch = match[1];
  var state = match[0];
  var match$1 = state.formStatus;
  var tmp;
  tmp = typeof match$1 === "number" || match$1.tag ? false : true;
  return {
          updatePasswordConfirmation: (function (nextInputFn) {
              return Curry._1(dispatch, /* UpdatePasswordConfirmationField */Block.__(0, [nextInputFn]));
            }),
          updatePassword: (function (nextInputFn) {
              return Curry._1(dispatch, /* UpdatePasswordField */Block.__(1, [nextInputFn]));
            }),
          updateEmail: (function (nextInputFn) {
              return Curry._1(dispatch, /* UpdateEmailField */Block.__(2, [nextInputFn]));
            }),
          blurPasswordConfirmation: (function (param) {
              return Curry._1(dispatch, /* BlurPasswordConfirmationField */0);
            }),
          blurPassword: (function (param) {
              return Curry._1(dispatch, /* BlurPasswordField */1);
            }),
          blurEmail: (function (param) {
              return Curry._1(dispatch, /* BlurEmailField */2);
            }),
          passwordConfirmationResult: Formality.exposeFieldResult(state.fieldsStatuses.passwordConfirmation),
          passwordResult: Formality.exposeFieldResult(state.fieldsStatuses.password),
          emailResult: Formality.Async.exposeFieldResult(state.fieldsStatuses.email),
          input: state.input,
          status: state.formStatus,
          dirty: (function (param) {
              var match = state.fieldsStatuses;
              if (typeof match.email === "number" && !(match.password || match.passwordConfirmation)) {
                return false;
              } else {
                return true;
              }
            }),
          valid: (function (param) {
              var match = validateForm(state.input, validators, state.fieldsStatuses);
              switch (match.tag | 0) {
                case /* Valid */0 :
                    return true;
                case /* Invalid */1 :
                    return false;
                case /* Validating */2 :
                    return ;
                
              }
            }),
          submitting: tmp,
          submit: (function (param) {
              return Curry._1(dispatch, /* Submit */3);
            }),
          dismissSubmissionError: (function (param) {
              return Curry._1(dispatch, /* DismissSubmissionError */4);
            }),
          dismissSubmissionResult: (function (param) {
              return Curry._1(dispatch, /* DismissSubmissionResult */5);
            }),
          mapSubmissionError: (function (map) {
              return Curry._1(dispatch, /* MapSubmissionError */Block.__(6, [map]));
            }),
          reset: (function (param) {
              return Curry._1(dispatch, /* Reset */6);
            })
        };
}

var SignupForm = {
  debounceInterval: 700,
  validators: validators,
  initialFieldsStatuses: initialFieldsStatuses,
  initialCollectionsStatuses: /* () */0,
  initialState: initialState,
  validateForm: validateForm,
  useForm: useForm
};

var initialInput = {
  email: "",
  password: "",
  passwordConfirmation: ""
};

function SignupForm$1(Props) {
  var form = useForm(initialInput, (function (output, form) {
          console.log("Submitted with:", output);
          setTimeout((function (param) {
                  Curry._1(form.notifyOnSuccess, undefined);
                  setTimeout(form.reset, 3000);
                  return /* () */0;
                }), 500);
          return /* () */0;
        }));
  var match = form.emailResult;
  var tmp;
  if (match !== undefined) {
    var match$1 = match;
    if (match$1.tag) {
      var match$2 = match$1[0];
      tmp = match$2.tag ? React.createElement("div", {
              className: Cn.make(/* :: */[
                    "form-message",
                    /* :: */[
                      "form-message-for-field",
                      /* :: */[
                        "failure",
                        /* [] */0
                      ]
                    ]
                  ])
            }, match$2[0]) : React.createElement("div", {
              className: Cn.make(/* :: */[
                    "form-message",
                    /* :: */[
                      "form-message-for-field",
                      /* :: */[
                        "success",
                        /* [] */0
                      ]
                    ]
                  ])
            }, "✓");
    } else {
      tmp = React.createElement("div", {
            className: Cn.make(/* :: */[
                  "form-message",
                  /* :: */[
                    "form-message-for-field",
                    /* [] */0
                  ]
                ])
          }, "Checking...");
    }
  } else {
    tmp = null;
  }
  var match$3 = form.passwordResult;
  var tmp$1;
  if (match$3 !== undefined) {
    var match$4 = match$3;
    tmp$1 = match$4.tag ? React.createElement("div", {
            className: Cn.make(/* :: */[
                  "form-message",
                  /* :: */[
                    "form-message-for-field",
                    /* :: */[
                      "failure",
                      /* [] */0
                    ]
                  ]
                ])
          }, match$4[0]) : React.createElement("div", {
            className: Cn.make(/* :: */[
                  "form-message",
                  /* :: */[
                    "form-message-for-field",
                    /* :: */[
                      "success",
                      /* [] */0
                    ]
                  ]
                ])
          }, "✓");
  } else {
    tmp$1 = null;
  }
  var match$5 = form.passwordConfirmationResult;
  var tmp$2;
  if (match$5 !== undefined) {
    var match$6 = match$5;
    tmp$2 = match$6.tag ? React.createElement("div", {
            className: Cn.make(/* :: */[
                  "form-message",
                  /* :: */[
                    "form-message-for-field",
                    /* :: */[
                      "failure",
                      /* [] */0
                    ]
                  ]
                ])
          }, match$6[0]) : React.createElement("div", {
            className: Cn.make(/* :: */[
                  "form-message",
                  /* :: */[
                    "form-message-for-field",
                    /* :: */[
                      "success",
                      /* [] */0
                    ]
                  ]
                ])
          }, "✓");
  } else {
    tmp$2 = null;
  }
  var match$7 = form.status;
  return React.createElement(Form.make, {
              className: "form",
              onSubmit: form.submit,
              children: null
            }, React.createElement("div", {
                  className: "form-messages-area form-messages-area-lg"
                }), React.createElement("div", {
                  className: "form-content"
                }, React.createElement("h2", {
                      className: "push-lg"
                    }, "Signup"), React.createElement("div", {
                      className: "form-row"
                    }, React.createElement("label", {
                          className: "label-lg",
                          htmlFor: "signup--email"
                        }, "Email"), React.createElement("input", {
                          id: "signup--email",
                          disabled: form.submitting,
                          type: "text",
                          value: form.input.email,
                          onBlur: (function (param) {
                              return Curry._1(form.blurEmail, /* () */0);
                            }),
                          onChange: (function ($$event) {
                              var value = $$event.target.value;
                              return Curry._1(form.updateEmail, (function (input) {
                                            return {
                                                    email: value,
                                                    password: input.password,
                                                    passwordConfirmation: input.passwordConfirmation
                                                  };
                                          }));
                            })
                        }), tmp), React.createElement("div", {
                      className: "form-row form-row-footer"
                    }, React.createElement("div", {
                          className: "note push-lg"
                        }, "Hint: try `test@taken.email`")), React.createElement("div", {
                      className: "form-row"
                    }, React.createElement("label", {
                          className: "label-lg",
                          htmlFor: "signup--password"
                        }, "Password"), React.createElement("input", {
                          id: "signup--password",
                          disabled: form.submitting,
                          type: "text",
                          value: form.input.password,
                          onBlur: (function (param) {
                              return Curry._1(form.blurPassword, /* () */0);
                            }),
                          onChange: (function ($$event) {
                              var value = $$event.target.value;
                              return Curry._1(form.updatePassword, (function (input) {
                                            return {
                                                    email: input.email,
                                                    password: value,
                                                    passwordConfirmation: input.passwordConfirmation
                                                  };
                                          }));
                            })
                        }), tmp$1), React.createElement("div", {
                      className: "form-row"
                    }, React.createElement("label", {
                          className: "label-lg",
                          htmlFor: "signup--passwordConfirmation"
                        }, "Confirmation"), React.createElement("input", {
                          id: "signup--passwordConfirmation",
                          disabled: form.submitting,
                          type: "text",
                          value: form.input.passwordConfirmation,
                          onBlur: (function (param) {
                              return Curry._1(form.blurPasswordConfirmation, /* () */0);
                            }),
                          onChange: (function ($$event) {
                              var value = $$event.target.value;
                              return Curry._1(form.updatePasswordConfirmation, (function (input) {
                                            return {
                                                    email: input.email,
                                                    password: input.password,
                                                    passwordConfirmation: value
                                                  };
                                          }));
                            })
                        }), tmp$2), React.createElement("div", {
                      className: "form-row"
                    }, React.createElement("button", {
                          className: Cn.make(/* :: */[
                                "primary",
                                /* :: */[
                                  "push-lg",
                                  /* [] */0
                                ]
                              ]),
                          disabled: form.submitting
                        }, form.submitting ? "Submitting..." : "Submit"), typeof match$7 === "number" && match$7 !== 0 ? React.createElement("div", {
                            className: Cn.make(/* :: */[
                                  "form-status",
                                  /* :: */[
                                    "success",
                                    /* [] */0
                                  ]
                                ])
                          }, "✓ Signed Up") : null)));
}

var make = SignupForm$1;

export {
  SignupForm ,
  initialInput ,
  make ,
  
}
/* validators Not a pure module */
