// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE

import * as Cn from "re-classnames/src/Cn.bs.js";
import * as React from "react";
import * as LoginForm from "./LoginForm.bs.js";
import * as SignupForm from "./SignupForm.bs.js";
import * as BlogPostForm from "./BlogPostForm.bs.js";
import * as ReasonReactRouter from "reason-react/src/ReasonReactRouter.js";

function fromUrl(url) {
  var match = url.hash;
  switch (match) {
    case "blog-post" :
        return /* BlogPost */2;
    case "login" :
        return /* Login */1;
    case "signup" :
        return /* Signup */0;
    default:
      return /* Signup */0;
  }
}

var Route = {
  fromUrl: fromUrl
};

function App(Props) {
  var route = fromUrl(ReasonReactRouter.useUrl(undefined, /* () */0));
  var tmp;
  switch (route) {
    case /* Signup */0 :
        tmp = React.createElement(SignupForm.make, { });
        break;
    case /* Login */1 :
        tmp = React.createElement(LoginForm.make, { });
        break;
    case /* BlogPost */2 :
        tmp = React.createElement(BlogPostForm.make, { });
        break;
    
  }
  return React.createElement("div", {
              className: "container"
            }, React.createElement("div", {
                  className: "header"
                }, React.createElement("h1", undefined, "Formality"), React.createElement("a", {
                      className: "link",
                      href: "https://github.com/alexfedoseev/re-formality"
                    }, "Github")), React.createElement("div", {
                  className: "nav"
                }, React.createElement("button", {
                      className: Cn.make(/* :: */[
                            "primary",
                            /* :: */[
                              "nav-link",
                              /* :: */[
                                route !== 0 ? "" : "active",
                                /* [] */0
                              ]
                            ]
                          ]),
                      onClick: (function (param) {
                          return ReasonReactRouter.push("#signup");
                        })
                    }, "Signup"), React.createElement("button", {
                      className: Cn.make(/* :: */[
                            "primary",
                            /* :: */[
                              "nav-link",
                              /* :: */[
                                route !== 1 ? "" : "active",
                                /* [] */0
                              ]
                            ]
                          ]),
                      onClick: (function (param) {
                          return ReasonReactRouter.push("#login");
                        })
                    }, "Login"), React.createElement("button", {
                      className: Cn.make(/* :: */[
                            "primary",
                            /* :: */[
                              "nav-link",
                              /* :: */[
                                route >= 2 ? "active" : "",
                                /* [] */0
                              ]
                            ]
                          ]),
                      onClick: (function (param) {
                          return ReasonReactRouter.push("#blog-post");
                        })
                    }, "Blog Post")), tmp);
}

var make = App;

export {
  Route ,
  make ,
  
}
/* react Not a pure module */
