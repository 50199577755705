// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE

import * as Cn from "re-classnames/src/Cn.bs.js";
import * as Form from "./Form.bs.js";
import * as Block from "bs-platform/lib/es6/block.js";
import * as Curry from "bs-platform/lib/es6/curry.js";
import * as React from "react";
import * as Formality from "re-formality/src/Formality.bs.js";
import * as Formality__ReactUpdate from "re-formality/src/Formality__ReactUpdate.bs.js";

var validators_email = {
  strategy: /* OnFirstSuccessOrFirstBlur */3,
  validate: (function (param) {
      var email = param.email;
      var emailRegex = /.*@.*\..+/;
      if (email === "") {
        return /* Error */Block.__(1, ["Email is required"]);
      } else if (emailRegex.test(email)) {
        return /* Ok */Block.__(0, [email]);
      } else {
        return /* Error */Block.__(1, ["Email is invalid"]);
      }
    })
};

var validators_password = {
  strategy: /* OnFirstBlur */0,
  validate: (function (param) {
      var password = param.password;
      if (password === "") {
        return /* Error */Block.__(1, ["Password is required"]);
      } else {
        return /* Ok */Block.__(0, [password]);
      }
    })
};

var validators = {
  email: validators_email,
  password: validators_password,
  rememberMe: /* () */0
};

function initialFieldsStatuses(_input) {
  return {
          email: /* Pristine */0,
          password: /* Pristine */0,
          rememberMe: /* Pristine */0
        };
}

function initialState(input) {
  return {
          input: input,
          fieldsStatuses: {
            email: /* Pristine */0,
            password: /* Pristine */0,
            rememberMe: /* Pristine */0
          },
          collectionsStatuses: /* () */0,
          formStatus: /* Editing */0,
          submissionStatus: /* NeverSubmitted */0
        };
}

function validateForm(input, validators, fieldsStatuses) {
  var match = fieldsStatuses.email;
  var match_000 = match ? match[0] : Curry._1(validators.email.validate, input);
  var match$1 = fieldsStatuses.password;
  var match_000$1 = match$1 ? match$1[0] : Curry._1(validators.password.validate, input);
  var match_000$2 = /* Ok */Block.__(0, [input.rememberMe]);
  var emailResult = match_000;
  var emailResult$1;
  if (emailResult.tag) {
    emailResult$1 = emailResult;
  } else {
    var passwordResult = match_000$1;
    if (passwordResult.tag) {
      emailResult$1 = emailResult;
    } else {
      var rememberMeResult = match_000$2;
      if (rememberMeResult.tag) {
        emailResult$1 = emailResult;
      } else {
        return /* Valid */Block.__(0, [
                  /* output */{
                    email: emailResult[0],
                    password: passwordResult[0],
                    rememberMe: rememberMeResult[0]
                  },
                  /* fieldsStatuses */{
                    email: /* Dirty */[
                      emailResult,
                      /* Shown */0
                    ],
                    password: /* Dirty */[
                      passwordResult,
                      /* Shown */0
                    ],
                    rememberMe: /* Dirty */[
                      rememberMeResult,
                      /* Hidden */1
                    ]
                  },
                  /* collectionsStatuses : () */0
                ]);
      }
    }
  }
  return /* Invalid */Block.__(1, [
            /* fieldsStatuses */{
              email: /* Dirty */[
                emailResult$1,
                /* Shown */0
              ],
              password: /* Dirty */[
                match_000$1,
                /* Shown */0
              ],
              rememberMe: /* Dirty */[
                match_000$2,
                /* Hidden */1
              ]
            },
            /* collectionsStatuses : () */0
          ]);
}

function useForm(initialInput, onSubmit) {
  var memoizedInitialState = React.useMemo((function () {
          return initialState(initialInput);
        }), [initialInput]);
  var match = Formality__ReactUpdate.useReducer(memoizedInitialState, (function (state, action) {
          if (typeof action === "number") {
            switch (action) {
              case /* BlurRememberMeField */0 :
                  var result = Formality.validateFieldOnBlurWithoutValidator(state.input.rememberMe, state.fieldsStatuses.rememberMe, (function (status) {
                          var init = state.fieldsStatuses;
                          return {
                                  email: init.email,
                                  password: init.password,
                                  rememberMe: status
                                };
                        }));
                  if (result !== undefined) {
                    return /* Update */Block.__(0, [{
                                input: state.input,
                                fieldsStatuses: result,
                                collectionsStatuses: state.collectionsStatuses,
                                formStatus: state.formStatus,
                                submissionStatus: state.submissionStatus
                              }]);
                  } else {
                    return /* NoUpdate */0;
                  }
              case /* BlurPasswordField */1 :
                  var result$1 = Formality.validateFieldOnBlurWithValidator(state.input, state.fieldsStatuses.password, validators_password, (function (status) {
                          var init = state.fieldsStatuses;
                          return {
                                  email: init.email,
                                  password: status,
                                  rememberMe: init.rememberMe
                                };
                        }));
                  if (result$1 !== undefined) {
                    return /* Update */Block.__(0, [{
                                input: state.input,
                                fieldsStatuses: result$1,
                                collectionsStatuses: state.collectionsStatuses,
                                formStatus: state.formStatus,
                                submissionStatus: state.submissionStatus
                              }]);
                  } else {
                    return /* NoUpdate */0;
                  }
              case /* BlurEmailField */2 :
                  var result$2 = Formality.validateFieldOnBlurWithValidator(state.input, state.fieldsStatuses.email, validators_email, (function (status) {
                          var init = state.fieldsStatuses;
                          return {
                                  email: status,
                                  password: init.password,
                                  rememberMe: init.rememberMe
                                };
                        }));
                  if (result$2 !== undefined) {
                    return /* Update */Block.__(0, [{
                                input: state.input,
                                fieldsStatuses: result$2,
                                collectionsStatuses: state.collectionsStatuses,
                                formStatus: state.formStatus,
                                submissionStatus: state.submissionStatus
                              }]);
                  } else {
                    return /* NoUpdate */0;
                  }
              case /* Submit */3 :
                  var match = state.formStatus;
                  if (typeof match !== "number" && !match.tag) {
                    return /* NoUpdate */0;
                  }
                  var match$1 = validateForm(state.input, validators, state.fieldsStatuses);
                  if (match$1.tag) {
                    return /* Update */Block.__(0, [{
                                input: state.input,
                                fieldsStatuses: match$1[/* fieldsStatuses */0],
                                collectionsStatuses: match$1[/* collectionsStatuses */1],
                                formStatus: /* Editing */0,
                                submissionStatus: /* AttemptedToSubmit */1
                              }]);
                  } else {
                    var output = match$1[/* output */0];
                    var match$2 = state.formStatus;
                    var tmp;
                    tmp = typeof match$2 === "number" || match$2.tag !== /* SubmissionFailed */1 ? undefined : match$2[0];
                    return /* UpdateWithSideEffects */Block.__(1, [
                              {
                                input: state.input,
                                fieldsStatuses: match$1[/* fieldsStatuses */1],
                                collectionsStatuses: match$1[/* collectionsStatuses */2],
                                formStatus: /* Submitting */Block.__(0, [tmp]),
                                submissionStatus: /* AttemptedToSubmit */1
                              },
                              (function (param) {
                                  var dispatch = param.dispatch;
                                  return Curry._2(onSubmit, output, {
                                              notifyOnSuccess: (function (input) {
                                                  return Curry._1(dispatch, /* SetSubmittedStatus */Block.__(3, [input]));
                                                }),
                                              notifyOnFailure: (function (error) {
                                                  return Curry._1(dispatch, /* SetSubmissionFailedStatus */Block.__(4, [error]));
                                                }),
                                              reset: (function (param) {
                                                  return Curry._1(dispatch, /* Reset */6);
                                                }),
                                              dismissSubmissionResult: (function (param) {
                                                  return Curry._1(dispatch, /* DismissSubmissionResult */5);
                                                })
                                            });
                                })
                            ]);
                  }
                  break;
              case /* DismissSubmissionError */4 :
                  var match$3 = state.formStatus;
                  if (typeof match$3 === "number" || match$3.tag !== /* SubmissionFailed */1) {
                    return /* NoUpdate */0;
                  } else {
                    return /* Update */Block.__(0, [{
                                input: state.input,
                                fieldsStatuses: state.fieldsStatuses,
                                collectionsStatuses: state.collectionsStatuses,
                                formStatus: /* Editing */0,
                                submissionStatus: state.submissionStatus
                              }]);
                  }
              case /* DismissSubmissionResult */5 :
                  var match$4 = state.formStatus;
                  if (typeof match$4 === "number") {
                    if (match$4 === /* Editing */0) {
                      return /* NoUpdate */0;
                    }
                    
                  } else if (!match$4.tag) {
                    return /* NoUpdate */0;
                  }
                  return /* Update */Block.__(0, [{
                              input: state.input,
                              fieldsStatuses: state.fieldsStatuses,
                              collectionsStatuses: state.collectionsStatuses,
                              formStatus: /* Editing */0,
                              submissionStatus: state.submissionStatus
                            }]);
              case /* Reset */6 :
                  return /* Update */Block.__(0, [initialState(initialInput)]);
              
            }
          } else {
            switch (action.tag | 0) {
              case /* UpdateRememberMeField */0 :
                  var nextInput = Curry._1(action[0], state.input);
                  return /* Update */Block.__(0, [{
                              input: nextInput,
                              fieldsStatuses: Formality.validateFieldOnChangeWithoutValidator(nextInput.rememberMe, (function (status) {
                                      var init = state.fieldsStatuses;
                                      return {
                                              email: init.email,
                                              password: init.password,
                                              rememberMe: status
                                            };
                                    })),
                              collectionsStatuses: state.collectionsStatuses,
                              formStatus: state.formStatus,
                              submissionStatus: state.submissionStatus
                            }]);
              case /* UpdatePasswordField */1 :
                  var nextInput$1 = Curry._1(action[0], state.input);
                  return /* Update */Block.__(0, [{
                              input: nextInput$1,
                              fieldsStatuses: Formality.validateFieldOnChangeWithValidator(nextInput$1, state.fieldsStatuses.password, state.submissionStatus, validators_password, (function (status) {
                                      var init = state.fieldsStatuses;
                                      return {
                                              email: init.email,
                                              password: status,
                                              rememberMe: init.rememberMe
                                            };
                                    })),
                              collectionsStatuses: state.collectionsStatuses,
                              formStatus: state.formStatus,
                              submissionStatus: state.submissionStatus
                            }]);
              case /* UpdateEmailField */2 :
                  var nextInput$2 = Curry._1(action[0], state.input);
                  return /* Update */Block.__(0, [{
                              input: nextInput$2,
                              fieldsStatuses: Formality.validateFieldOnChangeWithValidator(nextInput$2, state.fieldsStatuses.email, state.submissionStatus, validators_email, (function (status) {
                                      var init = state.fieldsStatuses;
                                      return {
                                              email: status,
                                              password: init.password,
                                              rememberMe: init.rememberMe
                                            };
                                    })),
                              collectionsStatuses: state.collectionsStatuses,
                              formStatus: state.formStatus,
                              submissionStatus: state.submissionStatus
                            }]);
              case /* SetSubmittedStatus */3 :
                  var input = action[0];
                  if (input !== undefined) {
                    return /* Update */Block.__(0, [{
                                input: input,
                                fieldsStatuses: {
                                  email: /* Pristine */0,
                                  password: /* Pristine */0,
                                  rememberMe: /* Pristine */0
                                },
                                collectionsStatuses: state.collectionsStatuses,
                                formStatus: /* Submitted */1,
                                submissionStatus: state.submissionStatus
                              }]);
                  } else {
                    return /* Update */Block.__(0, [{
                                input: state.input,
                                fieldsStatuses: {
                                  email: /* Pristine */0,
                                  password: /* Pristine */0,
                                  rememberMe: /* Pristine */0
                                },
                                collectionsStatuses: state.collectionsStatuses,
                                formStatus: /* Submitted */1,
                                submissionStatus: state.submissionStatus
                              }]);
                  }
              case /* SetSubmissionFailedStatus */4 :
                  return /* Update */Block.__(0, [{
                              input: state.input,
                              fieldsStatuses: state.fieldsStatuses,
                              collectionsStatuses: state.collectionsStatuses,
                              formStatus: /* SubmissionFailed */Block.__(1, [action[0]]),
                              submissionStatus: state.submissionStatus
                            }]);
              case /* MapSubmissionError */5 :
                  var map = action[0];
                  var match$5 = state.formStatus;
                  if (typeof match$5 === "number") {
                    return /* NoUpdate */0;
                  } else if (match$5.tag) {
                    return /* Update */Block.__(0, [{
                                input: state.input,
                                fieldsStatuses: state.fieldsStatuses,
                                collectionsStatuses: state.collectionsStatuses,
                                formStatus: /* SubmissionFailed */Block.__(1, [Curry._1(map, match$5[0])]),
                                submissionStatus: state.submissionStatus
                              }]);
                  } else {
                    var match$6 = match$5[0];
                    if (match$6 !== undefined) {
                      return /* Update */Block.__(0, [{
                                  input: state.input,
                                  fieldsStatuses: state.fieldsStatuses,
                                  collectionsStatuses: state.collectionsStatuses,
                                  formStatus: /* Submitting */Block.__(0, [Curry._1(map, match$6)]),
                                  submissionStatus: state.submissionStatus
                                }]);
                    } else {
                      return /* NoUpdate */0;
                    }
                  }
              
            }
          }
        }));
  var dispatch = match[1];
  var state = match[0];
  var match$1 = state.formStatus;
  var tmp;
  tmp = typeof match$1 === "number" || match$1.tag ? false : true;
  return {
          updateRememberMe: (function (nextInputFn) {
              return Curry._1(dispatch, /* UpdateRememberMeField */Block.__(0, [nextInputFn]));
            }),
          updatePassword: (function (nextInputFn) {
              return Curry._1(dispatch, /* UpdatePasswordField */Block.__(1, [nextInputFn]));
            }),
          updateEmail: (function (nextInputFn) {
              return Curry._1(dispatch, /* UpdateEmailField */Block.__(2, [nextInputFn]));
            }),
          blurRememberMe: (function (param) {
              return Curry._1(dispatch, /* BlurRememberMeField */0);
            }),
          blurPassword: (function (param) {
              return Curry._1(dispatch, /* BlurPasswordField */1);
            }),
          blurEmail: (function (param) {
              return Curry._1(dispatch, /* BlurEmailField */2);
            }),
          rememberMeResult: Formality.exposeFieldResult(state.fieldsStatuses.rememberMe),
          passwordResult: Formality.exposeFieldResult(state.fieldsStatuses.password),
          emailResult: Formality.exposeFieldResult(state.fieldsStatuses.email),
          input: state.input,
          status: state.formStatus,
          dirty: (function (param) {
              var match = state.fieldsStatuses;
              if (match.email || match.password || match.rememberMe) {
                return true;
              } else {
                return false;
              }
            }),
          valid: (function (param) {
              var match = validateForm(state.input, validators, state.fieldsStatuses);
              if (match.tag) {
                return false;
              } else {
                return true;
              }
            }),
          submitting: tmp,
          submit: (function (param) {
              return Curry._1(dispatch, /* Submit */3);
            }),
          dismissSubmissionError: (function (param) {
              return Curry._1(dispatch, /* DismissSubmissionError */4);
            }),
          dismissSubmissionResult: (function (param) {
              return Curry._1(dispatch, /* DismissSubmissionResult */5);
            }),
          mapSubmissionError: (function (map) {
              return Curry._1(dispatch, /* MapSubmissionError */Block.__(5, [map]));
            }),
          reset: (function (param) {
              return Curry._1(dispatch, /* Reset */6);
            })
        };
}

var LoginForm = {
  validators: validators,
  initialFieldsStatuses: initialFieldsStatuses,
  initialCollectionsStatuses: /* () */0,
  initialState: initialState,
  validateForm: validateForm,
  useForm: useForm
};

var initialInput = {
  email: "",
  password: "",
  rememberMe: false
};

function LoginForm$1(Props) {
  var form = useForm(initialInput, (function (output, form) {
          console.log("Submitted with:", output);
          setTimeout((function (param) {
                  Curry._1(form.notifyOnSuccess, undefined);
                  setTimeout(form.reset, 3000);
                  return /* () */0;
                }), 500);
          return /* () */0;
        }));
  var match = form.emailResult;
  var tmp;
  if (match !== undefined) {
    var match$1 = match;
    tmp = match$1.tag ? React.createElement("div", {
            className: Cn.make(/* :: */[
                  "form-message",
                  /* :: */[
                    "form-message-for-field",
                    /* :: */[
                      "failure",
                      /* [] */0
                    ]
                  ]
                ])
          }, match$1[0]) : React.createElement("div", {
            className: Cn.make(/* :: */[
                  "form-message",
                  /* :: */[
                    "form-message-for-field",
                    /* :: */[
                      "success",
                      /* [] */0
                    ]
                  ]
                ])
          }, "✓");
  } else {
    tmp = null;
  }
  var match$2 = form.passwordResult;
  var tmp$1;
  if (match$2 !== undefined) {
    var match$3 = match$2;
    tmp$1 = match$3.tag ? React.createElement("div", {
            className: Cn.make(/* :: */[
                  "form-message",
                  /* :: */[
                    "form-message-for-field",
                    /* :: */[
                      "failure",
                      /* [] */0
                    ]
                  ]
                ])
          }, match$3[0]) : React.createElement("div", {
            className: Cn.make(/* :: */[
                  "form-message",
                  /* :: */[
                    "form-message-for-field",
                    /* :: */[
                      "success",
                      /* [] */0
                    ]
                  ]
                ])
          }, "✓");
  } else {
    tmp$1 = null;
  }
  var match$4 = form.status;
  return React.createElement(Form.make, {
              className: "form",
              onSubmit: form.submit,
              children: null
            }, React.createElement("div", {
                  className: "form-messages-area form-messages-area-lg"
                }), React.createElement("div", {
                  className: "form-content"
                }, React.createElement("h2", {
                      className: "push-lg"
                    }, "Login"), React.createElement("div", {
                      className: "form-row"
                    }, React.createElement("label", {
                          className: "label-lg",
                          htmlFor: "login--email"
                        }, "Email"), React.createElement("input", {
                          id: "login--email",
                          disabled: form.submitting,
                          type: "text",
                          value: form.input.email,
                          onBlur: (function (param) {
                              return Curry._1(form.blurEmail, /* () */0);
                            }),
                          onChange: (function ($$event) {
                              var value = $$event.target.value;
                              return Curry._1(form.updateEmail, (function (input) {
                                            return {
                                                    email: value,
                                                    password: input.password,
                                                    rememberMe: input.rememberMe
                                                  };
                                          }));
                            })
                        }), tmp), React.createElement("div", {
                      className: "form-row"
                    }, React.createElement("label", {
                          className: "label-lg",
                          htmlFor: "login--password"
                        }, "Password"), React.createElement("input", {
                          id: "login--password",
                          disabled: form.submitting,
                          type: "text",
                          value: form.input.password,
                          onBlur: (function (param) {
                              return Curry._1(form.blurPassword, /* () */0);
                            }),
                          onChange: (function ($$event) {
                              var value = $$event.target.value;
                              return Curry._1(form.updatePassword, (function (input) {
                                            return {
                                                    email: input.email,
                                                    password: value,
                                                    rememberMe: input.rememberMe
                                                  };
                                          }));
                            })
                        }), tmp$1), React.createElement("div", {
                      className: "form-row"
                    }, React.createElement("input", {
                          className: "push-lg",
                          id: "login--remember",
                          checked: form.input.rememberMe,
                          disabled: form.submitting,
                          type: "checkbox",
                          onBlur: (function (param) {
                              return Curry._1(form.blurRememberMe, /* () */0);
                            }),
                          onChange: (function ($$event) {
                              var checked = $$event.target.checked;
                              return Curry._1(form.updateRememberMe, (function (input) {
                                            return {
                                                    email: input.email,
                                                    password: input.password,
                                                    rememberMe: checked
                                                  };
                                          }));
                            })
                        }), React.createElement("label", {
                          htmlFor: "login--remember"
                        }, "Remember me")), React.createElement("div", {
                      className: "form-row"
                    }, React.createElement("button", {
                          className: Cn.make(/* :: */[
                                "primary",
                                /* :: */[
                                  "push-lg",
                                  /* [] */0
                                ]
                              ]),
                          disabled: form.submitting
                        }, form.submitting ? "Submitting..." : "Submit"), typeof match$4 === "number" && match$4 !== 0 ? React.createElement("div", {
                            className: Cn.make(/* :: */[
                                  "form-status",
                                  /* :: */[
                                    "success",
                                    /* [] */0
                                  ]
                                ])
                          }, "✓ Logged In") : null)));
}

var make = LoginForm$1;

export {
  LoginForm ,
  initialInput ,
  make ,
  
}
/* Form Not a pure module */
