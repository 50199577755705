// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE

import * as App from "./App.bs.js";
import * as React from "react";
import * as ReactDOMRe from "reason-react/src/ReactDOMRe.js";

ReactDOMRe.renderToElementWithId(React.createElement(App.make, { }), "app");

export {
  
}
/*  Not a pure module */
