// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE

import * as Curry from "bs-platform/lib/es6/curry.js";
import * as React from "react";

function Form(Props) {
  var className = Props.className;
  var onSubmit = Props.onSubmit;
  var children = Props.children;
  return React.createElement("form", {
              className: className,
              onSubmit: (function ($$event) {
                  if (!$$event.defaultPrevented) {
                    $$event.preventDefault();
                  }
                  return Curry._1(onSubmit, /* () */0);
                })
            }, children);
}

var make = Form;

export {
  make ,
  
}
/* react Not a pure module */
