// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE

import * as Cn from "re-classnames/src/Cn.bs.js";
import * as Form from "./Form.bs.js";
import * as Block from "bs-platform/lib/es6/block.js";
import * as Curry from "bs-platform/lib/es6/curry.js";
import * as React from "react";
import * as Formality from "re-formality/src/Formality.bs.js";
import * as Belt_Array from "bs-platform/lib/es6/belt_Array.js";
import * as Formality__ReactUpdate from "re-formality/src/Formality__ReactUpdate.bs.js";

var validators_title = {
  strategy: /* OnFirstSuccessOrFirstBlur */3,
  validate: (function (param) {
      var title = param.title;
      if (title === "") {
        return /* Error */Block.__(1, ["Title is required"]);
      } else {
        return /* Ok */Block.__(0, [title]);
      }
    })
};

var validators_authors = {
  collection: (function (input) {
      var match = input.authors;
      if (match.length !== 0) {
        return /* Ok */Block.__(0, [/* () */0]);
      } else {
        return /* Error */Block.__(1, ["There must be at least one author"]);
      }
    }),
  fields: {
    name: {
      strategy: /* OnFirstSuccessOrFirstBlur */3,
      validate: (function (param, at) {
          var authors = param.authors;
          var match = authors[at];
          var name = match.name;
          if (name === "") {
            return /* Error */Block.__(1, ["Author name is required"]);
          } else if (authors.some((function (author, idx) {
                    return at === idx ? false : author.name === name;
                  }))) {
            return /* Error */Block.__(1, ["Author name must be uniq"]);
          } else {
            return /* Ok */Block.__(0, [name]);
          }
        })
    }
  }
};

var validators = {
  title: validators_title,
  authors: validators_authors
};

function initialFieldsStatuses(input) {
  return {
          title: /* Pristine */0,
          authors: Belt_Array.make(input.authors.length, {
                name: /* Pristine */0
              })
        };
}

var initialCollectionsStatuses = {
  authors: undefined
};

function initialState(input) {
  return {
          input: input,
          fieldsStatuses: initialFieldsStatuses(input),
          collectionsStatuses: initialCollectionsStatuses,
          formStatus: /* Editing */0,
          submissionStatus: /* NeverSubmitted */0
        };
}

function validateForm(input, validators, fieldsStatuses) {
  var match = fieldsStatuses.title;
  var match_000 = match ? match[0] : Curry._1(validators.title.validate, input);
  var match_000$1 = Curry._1(validators.authors.collection, input);
  var match_001 = Belt_Array.reduceWithIndex(fieldsStatuses.authors, /* tuple */[
        /* Ok */Block.__(0, [[]]),
        []
      ], (function (param, fieldStatus, index) {
          var statuses = param[1];
          var output = param[0];
          var match = fieldStatus.name;
          var match_000 = match ? match[0] : Curry._2(validators.authors.fields.name.validate, input, index);
          if (!output.tag) {
            var nameResult = match_000;
            var output$1 = output[0];
            if (!nameResult.tag) {
              output$1.push({
                    name: nameResult[0]
                  });
              statuses.push({
                    name: /* Dirty */[
                      nameResult,
                      /* Shown */0
                    ]
                  });
              return /* tuple */[
                      /* Ok */Block.__(0, [output$1]),
                      statuses
                    ];
            }
            
          }
          statuses.push({
                name: /* Dirty */[
                  match_000,
                  /* Shown */0
                ]
              });
          return /* tuple */[
                  /* Error */Block.__(1, [/* () */0]),
                  statuses
                ];
        }));
  var titleResult = match_000;
  var titleResult$1;
  if (titleResult.tag) {
    titleResult$1 = titleResult;
  } else {
    var authorsCollectionResult = match_000$1;
    if (authorsCollectionResult.tag) {
      titleResult$1 = titleResult;
    } else {
      var match$1 = match_001;
      var match$2 = match$1[0];
      if (match$2.tag) {
        titleResult$1 = titleResult;
      } else {
        return /* Valid */Block.__(0, [
                  /* output */{
                    title: titleResult[0],
                    authors: match$2[0]
                  },
                  /* fieldsStatuses */{
                    title: /* Dirty */[
                      titleResult,
                      /* Shown */0
                    ],
                    authors: match$1[1]
                  },
                  /* collectionsStatuses */{
                    authors: authorsCollectionResult
                  }
                ]);
      }
    }
  }
  return /* Invalid */Block.__(1, [
            /* fieldsStatuses */{
              title: /* Dirty */[
                titleResult$1,
                /* Shown */0
              ],
              authors: match_001[1]
            },
            /* collectionsStatuses */{
              authors: match_000$1
            }
          ]);
}

function useForm(initialInput, onSubmit) {
  var memoizedInitialState = React.useMemo((function () {
          return initialState(initialInput);
        }), [initialInput]);
  var match = Formality__ReactUpdate.useReducer(memoizedInitialState, (function (state, action) {
          if (typeof action === "number") {
            switch (action) {
              case /* BlurTitleField */0 :
                  var result = Formality.validateFieldOnBlurWithValidator(state.input, state.fieldsStatuses.title, validators_title, (function (status) {
                          var init = state.fieldsStatuses;
                          return {
                                  title: status,
                                  authors: init.authors
                                };
                        }));
                  if (result !== undefined) {
                    return /* Update */Block.__(0, [{
                                input: state.input,
                                fieldsStatuses: result,
                                collectionsStatuses: state.collectionsStatuses,
                                formStatus: state.formStatus,
                                submissionStatus: state.submissionStatus
                              }]);
                  } else {
                    return /* NoUpdate */0;
                  }
              case /* Submit */1 :
                  var match = state.formStatus;
                  if (typeof match !== "number" && !match.tag) {
                    return /* NoUpdate */0;
                  }
                  var match$1 = validateForm(state.input, validators, state.fieldsStatuses);
                  if (match$1.tag) {
                    return /* Update */Block.__(0, [{
                                input: state.input,
                                fieldsStatuses: match$1[/* fieldsStatuses */0],
                                collectionsStatuses: match$1[/* collectionsStatuses */1],
                                formStatus: /* Editing */0,
                                submissionStatus: /* AttemptedToSubmit */1
                              }]);
                  } else {
                    var output = match$1[/* output */0];
                    var match$2 = state.formStatus;
                    var tmp;
                    tmp = typeof match$2 === "number" || match$2.tag !== /* SubmissionFailed */1 ? undefined : match$2[0];
                    return /* UpdateWithSideEffects */Block.__(1, [
                              {
                                input: state.input,
                                fieldsStatuses: match$1[/* fieldsStatuses */1],
                                collectionsStatuses: match$1[/* collectionsStatuses */2],
                                formStatus: /* Submitting */Block.__(0, [tmp]),
                                submissionStatus: /* AttemptedToSubmit */1
                              },
                              (function (param) {
                                  var dispatch = param.dispatch;
                                  return Curry._2(onSubmit, output, {
                                              notifyOnSuccess: (function (input) {
                                                  return Curry._1(dispatch, /* SetSubmittedStatus */Block.__(5, [input]));
                                                }),
                                              notifyOnFailure: (function (error) {
                                                  return Curry._1(dispatch, /* SetSubmissionFailedStatus */Block.__(6, [error]));
                                                }),
                                              reset: (function (param) {
                                                  return Curry._1(dispatch, /* Reset */4);
                                                }),
                                              dismissSubmissionResult: (function (param) {
                                                  return Curry._1(dispatch, /* DismissSubmissionResult */3);
                                                })
                                            });
                                })
                            ]);
                  }
                  break;
              case /* DismissSubmissionError */2 :
                  var match$3 = state.formStatus;
                  if (typeof match$3 === "number" || match$3.tag !== /* SubmissionFailed */1) {
                    return /* NoUpdate */0;
                  } else {
                    return /* Update */Block.__(0, [{
                                input: state.input,
                                fieldsStatuses: state.fieldsStatuses,
                                collectionsStatuses: state.collectionsStatuses,
                                formStatus: /* Editing */0,
                                submissionStatus: state.submissionStatus
                              }]);
                  }
              case /* DismissSubmissionResult */3 :
                  var match$4 = state.formStatus;
                  if (typeof match$4 === "number") {
                    if (match$4 === /* Editing */0) {
                      return /* NoUpdate */0;
                    }
                    
                  } else if (!match$4.tag) {
                    return /* NoUpdate */0;
                  }
                  return /* Update */Block.__(0, [{
                              input: state.input,
                              fieldsStatuses: state.fieldsStatuses,
                              collectionsStatuses: state.collectionsStatuses,
                              formStatus: /* Editing */0,
                              submissionStatus: state.submissionStatus
                            }]);
              case /* Reset */4 :
                  return /* Update */Block.__(0, [initialState(initialInput)]);
              
            }
          } else {
            switch (action.tag | 0) {
              case /* UpdateAuthorNameField */0 :
                  var index = action[1];
                  var nextInput = Curry._1(action[0], state.input);
                  var nextFieldsStatuses = {
                    contents: state.fieldsStatuses
                  };
                  Belt_Array.forEachWithIndex(nextFieldsStatuses.contents.authors, (function (index$prime, item) {
                          if (index !== index$prime) {
                            var match = Formality.validateDependentFieldOfCollectionOnChange(nextInput, index$prime, item.name, validators_authors.fields.name, (function (status) {
                                    var init = nextFieldsStatuses.contents;
                                    return {
                                            title: init.title,
                                            authors: Belt_Array.mapWithIndex(nextFieldsStatuses.contents.authors, (function (idx_, item) {
                                                    if (idx_ === index$prime) {
                                                      return {
                                                              name: status
                                                            };
                                                    } else {
                                                      return item;
                                                    }
                                                  }))
                                          };
                                  }));
                            if (match !== undefined) {
                              nextFieldsStatuses.contents = match;
                              return /* () */0;
                            } else {
                              return /* () */0;
                            }
                          } else {
                            return /* () */0;
                          }
                        }));
                  return /* Update */Block.__(0, [{
                              input: nextInput,
                              fieldsStatuses: Formality.validateFieldOfCollectionOnChangeWithValidator(nextInput, index, nextFieldsStatuses.contents.authors[index].name, state.submissionStatus, validators_authors.fields.name, (function (status) {
                                      var init = nextFieldsStatuses.contents;
                                      return {
                                              title: init.title,
                                              authors: Belt_Array.mapWithIndex(nextFieldsStatuses.contents.authors, (function (idx_, item) {
                                                      if (idx_ === index) {
                                                        return {
                                                                name: status
                                                              };
                                                      } else {
                                                        return item;
                                                      }
                                                    }))
                                            };
                                    })),
                              collectionsStatuses: state.collectionsStatuses,
                              formStatus: state.formStatus,
                              submissionStatus: state.submissionStatus
                            }]);
              case /* UpdateTitleField */1 :
                  var nextInput$1 = Curry._1(action[0], state.input);
                  return /* Update */Block.__(0, [{
                              input: nextInput$1,
                              fieldsStatuses: Formality.validateFieldOnChangeWithValidator(nextInput$1, state.fieldsStatuses.title, state.submissionStatus, validators_title, (function (status) {
                                      var init = state.fieldsStatuses;
                                      return {
                                              title: status,
                                              authors: init.authors
                                            };
                                    })),
                              collectionsStatuses: state.collectionsStatuses,
                              formStatus: state.formStatus,
                              submissionStatus: state.submissionStatus
                            }]);
              case /* BlurAuthorNameField */2 :
                  var index$1 = action[0];
                  var result$1 = Formality.validateFieldOfCollectionOnBlurWithValidator(state.input, index$1, state.fieldsStatuses.authors[index$1].name, validators_authors.fields.name, (function (status) {
                          var init = state.fieldsStatuses;
                          return {
                                  title: init.title,
                                  authors: Belt_Array.mapWithIndex(state.fieldsStatuses.authors, (function (index$prime, item) {
                                          if (index$prime === index$1) {
                                            return {
                                                    name: status
                                                  };
                                          } else {
                                            return item;
                                          }
                                        }))
                                };
                        }));
                  if (result$1 !== undefined) {
                    return /* Update */Block.__(0, [{
                                input: state.input,
                                fieldsStatuses: result$1,
                                collectionsStatuses: state.collectionsStatuses,
                                formStatus: state.formStatus,
                                submissionStatus: state.submissionStatus
                              }]);
                  } else {
                    return /* NoUpdate */0;
                  }
              case /* AddAuthorEntry */3 :
                  var init = state.input;
                  var nextInput_title = init.title;
                  var nextInput_authors = Belt_Array.concat(state.input.authors, [action[0]]);
                  var nextInput$2 = {
                    title: nextInput_title,
                    authors: nextInput_authors
                  };
                  var init$1 = state.fieldsStatuses;
                  var nextFieldsStatuses$1 = {
                    contents: {
                      title: init$1.title,
                      authors: Belt_Array.concat(state.fieldsStatuses.authors, [{
                              name: /* Pristine */0
                            }])
                    }
                  };
                  Belt_Array.forEachWithIndex(nextFieldsStatuses$1.contents.authors, (function (index$prime, item) {
                          var match = Formality.validateDependentFieldOfCollectionOnChange(nextInput$2, index$prime, item.name, validators_authors.fields.name, (function (status) {
                                  var init = nextFieldsStatuses$1.contents;
                                  return {
                                          title: init.title,
                                          authors: Belt_Array.mapWithIndex(nextFieldsStatuses$1.contents.authors, (function (idx_, item) {
                                                  if (idx_ === index$prime) {
                                                    return {
                                                            name: status
                                                          };
                                                  } else {
                                                    return item;
                                                  }
                                                }))
                                        };
                                }));
                          if (match !== undefined) {
                            nextFieldsStatuses$1.contents = match;
                            return /* () */0;
                          } else {
                            return /* () */0;
                          }
                        }));
                  return /* Update */Block.__(0, [{
                              input: nextInput$2,
                              fieldsStatuses: nextFieldsStatuses$1.contents,
                              collectionsStatuses: {
                                authors: Curry._1(validators_authors.collection, nextInput$2)
                              },
                              formStatus: state.formStatus,
                              submissionStatus: state.submissionStatus
                            }]);
              case /* RemoveAuthorEntry */4 :
                  var index$2 = action[0];
                  var init$2 = state.input;
                  var nextInput_title$1 = init$2.title;
                  var nextInput_authors$1 = Belt_Array.keepWithIndex(state.input.authors, (function (param, i) {
                          return i !== index$2;
                        }));
                  var nextInput$3 = {
                    title: nextInput_title$1,
                    authors: nextInput_authors$1
                  };
                  var init$3 = state.fieldsStatuses;
                  var nextFieldsStatuses$2 = {
                    contents: {
                      title: init$3.title,
                      authors: Belt_Array.keepWithIndex(state.fieldsStatuses.authors, (function (param, i) {
                              return i !== index$2;
                            }))
                    }
                  };
                  Belt_Array.forEachWithIndex(nextFieldsStatuses$2.contents.authors, (function (index$prime, item) {
                          var match = Formality.validateDependentFieldOfCollectionOnChange(nextInput$3, index$prime, item.name, validators_authors.fields.name, (function (status) {
                                  var init = nextFieldsStatuses$2.contents;
                                  return {
                                          title: init.title,
                                          authors: Belt_Array.mapWithIndex(nextFieldsStatuses$2.contents.authors, (function (idx_, item) {
                                                  if (idx_ === index$prime) {
                                                    return {
                                                            name: status
                                                          };
                                                  } else {
                                                    return item;
                                                  }
                                                }))
                                        };
                                }));
                          if (match !== undefined) {
                            nextFieldsStatuses$2.contents = match;
                            return /* () */0;
                          } else {
                            return /* () */0;
                          }
                        }));
                  return /* Update */Block.__(0, [{
                              input: nextInput$3,
                              fieldsStatuses: nextFieldsStatuses$2.contents,
                              collectionsStatuses: {
                                authors: Curry._1(validators_authors.collection, nextInput$3)
                              },
                              formStatus: state.formStatus,
                              submissionStatus: state.submissionStatus
                            }]);
              case /* SetSubmittedStatus */5 :
                  var input = action[0];
                  if (input !== undefined) {
                    var input$1 = input;
                    return /* Update */Block.__(0, [{
                                input: input$1,
                                fieldsStatuses: initialFieldsStatuses(input$1),
                                collectionsStatuses: state.collectionsStatuses,
                                formStatus: /* Submitted */1,
                                submissionStatus: state.submissionStatus
                              }]);
                  } else {
                    return /* Update */Block.__(0, [{
                                input: state.input,
                                fieldsStatuses: initialFieldsStatuses(state.input),
                                collectionsStatuses: state.collectionsStatuses,
                                formStatus: /* Submitted */1,
                                submissionStatus: state.submissionStatus
                              }]);
                  }
              case /* SetSubmissionFailedStatus */6 :
                  return /* Update */Block.__(0, [{
                              input: state.input,
                              fieldsStatuses: state.fieldsStatuses,
                              collectionsStatuses: state.collectionsStatuses,
                              formStatus: /* SubmissionFailed */Block.__(1, [action[0]]),
                              submissionStatus: state.submissionStatus
                            }]);
              case /* MapSubmissionError */7 :
                  var map = action[0];
                  var match$5 = state.formStatus;
                  if (typeof match$5 === "number") {
                    return /* NoUpdate */0;
                  } else if (match$5.tag) {
                    return /* Update */Block.__(0, [{
                                input: state.input,
                                fieldsStatuses: state.fieldsStatuses,
                                collectionsStatuses: state.collectionsStatuses,
                                formStatus: /* SubmissionFailed */Block.__(1, [Curry._1(map, match$5[0])]),
                                submissionStatus: state.submissionStatus
                              }]);
                  } else {
                    var match$6 = match$5[0];
                    if (match$6 !== undefined) {
                      return /* Update */Block.__(0, [{
                                  input: state.input,
                                  fieldsStatuses: state.fieldsStatuses,
                                  collectionsStatuses: state.collectionsStatuses,
                                  formStatus: /* Submitting */Block.__(0, [Curry._1(map, match$6)]),
                                  submissionStatus: state.submissionStatus
                                }]);
                    } else {
                      return /* NoUpdate */0;
                    }
                  }
              
            }
          }
        }));
  var dispatch = match[1];
  var state = match[0];
  var match$1 = state.formStatus;
  var tmp;
  tmp = typeof match$1 === "number" || match$1.tag ? false : true;
  return {
          updateAuthorName: (function (nextInputFn, index) {
              return Curry._1(dispatch, /* UpdateAuthorNameField */Block.__(0, [
                            nextInputFn,
                            index
                          ]));
            }),
          updateTitle: (function (nextInputFn) {
              return Curry._1(dispatch, /* UpdateTitleField */Block.__(1, [nextInputFn]));
            }),
          blurAuthorName: (function (index) {
              return Curry._1(dispatch, /* BlurAuthorNameField */Block.__(2, [index]));
            }),
          blurTitle: (function (param) {
              return Curry._1(dispatch, /* BlurTitleField */0);
            }),
          authorNameResult: (function (index) {
              return Formality.exposeFieldResult(state.fieldsStatuses.authors[index].name);
            }),
          titleResult: Formality.exposeFieldResult(state.fieldsStatuses.title),
          addAuthor: (function (entry) {
              return Curry._1(dispatch, /* AddAuthorEntry */Block.__(3, [entry]));
            }),
          removeAuthor: (function (index) {
              return Curry._1(dispatch, /* RemoveAuthorEntry */Block.__(4, [index]));
            }),
          authorsResult: state.collectionsStatuses.authors,
          input: state.input,
          status: state.formStatus,
          dirty: (function (param) {
              var match = state.fieldsStatuses;
              if (match.title || !Belt_Array.every(match.authors, (function (item) {
                        return item.name ? false : true;
                      }))) {
                return true;
              } else {
                return false;
              }
            }),
          valid: (function (param) {
              var match = validateForm(state.input, validators, state.fieldsStatuses);
              if (match.tag) {
                return false;
              } else {
                return true;
              }
            }),
          submitting: tmp,
          submit: (function (param) {
              return Curry._1(dispatch, /* Submit */1);
            }),
          dismissSubmissionError: (function (param) {
              return Curry._1(dispatch, /* DismissSubmissionError */2);
            }),
          dismissSubmissionResult: (function (param) {
              return Curry._1(dispatch, /* DismissSubmissionResult */3);
            }),
          mapSubmissionError: (function (map) {
              return Curry._1(dispatch, /* MapSubmissionError */Block.__(7, [map]));
            }),
          reset: (function (param) {
              return Curry._1(dispatch, /* Reset */4);
            })
        };
}

var BlogPostForm = {
  validators: validators,
  initialFieldsStatuses: initialFieldsStatuses,
  initialCollectionsStatuses: initialCollectionsStatuses,
  initialState: initialState,
  validateForm: validateForm,
  useForm: useForm
};

var initialInput_authors = [{
    name: ""
  }];

var initialInput = {
  title: "",
  authors: initialInput_authors
};

function BlogPostForm$1(Props) {
  var form = useForm(initialInput, (function (output, form) {
          console.log("Submitted with:", output);
          setTimeout((function (param) {
                  Curry._1(form.notifyOnSuccess, undefined);
                  setTimeout(form.reset, 3000);
                  return /* () */0;
                }), 500);
          return /* () */0;
        }));
  var match = form.titleResult;
  var tmp;
  if (match !== undefined) {
    var match$1 = match;
    tmp = match$1.tag ? React.createElement("div", {
            className: Cn.make(/* :: */[
                  "form-message",
                  /* :: */[
                    "form-message-for-field",
                    /* :: */[
                      "failure",
                      /* [] */0
                    ]
                  ]
                ])
          }, match$1[0]) : React.createElement("div", {
            className: Cn.make(/* :: */[
                  "form-message",
                  /* :: */[
                    "form-message-for-field",
                    /* :: */[
                      "success",
                      /* [] */0
                    ]
                  ]
                ])
          }, "✓");
  } else {
    tmp = null;
  }
  var match$2 = form.authorsResult;
  var tmp$1;
  if (match$2 !== undefined) {
    var match$3 = match$2;
    tmp$1 = match$3.tag ? React.createElement("div", {
            className: Cn.make(/* :: */[
                  "form-message",
                  /* :: */[
                    "form-message-for-field",
                    /* :: */[
                      "failure",
                      /* [] */0
                    ]
                  ]
                ])
          }, match$3[0]) : null;
  } else {
    tmp$1 = null;
  }
  var match$4 = form.status;
  return React.createElement(Form.make, {
              className: "form",
              onSubmit: form.submit,
              children: null
            }, React.createElement("div", {
                  className: "form-messages-area form-messages-area-lg"
                }), React.createElement("div", {
                  className: "form-content"
                }, React.createElement("h2", {
                      className: "push-lg"
                    }, "Blog post"), React.createElement("div", {
                      className: "form-row"
                    }, React.createElement("label", {
                          className: "label-lg",
                          htmlFor: "blog-post--title"
                        }, "Title"), React.createElement("input", {
                          id: "blog-post--title",
                          disabled: form.submitting,
                          type: "text",
                          value: form.input.title,
                          onBlur: (function (param) {
                              return Curry._1(form.blurTitle, /* () */0);
                            }),
                          onChange: (function ($$event) {
                              var value = $$event.target.value;
                              return Curry._1(form.updateTitle, (function (input) {
                                            return {
                                                    title: value,
                                                    authors: input.authors
                                                  };
                                          }));
                            })
                        }), tmp), React.createElement("div", {
                      className: "form-row"
                    }, React.createElement("h3", {
                          className: "push-lg"
                        }, "Authors"), tmp$1), Belt_Array.mapWithIndex(form.input.authors, (function (index, author) {
                        var authorNameDomId = "blog-post--author-name" + String(index);
                        var match = Curry._1(form.authorNameResult, index);
                        var tmp;
                        if (match !== undefined) {
                          var match$1 = match;
                          tmp = match$1.tag ? React.createElement("div", {
                                  className: Cn.make(/* :: */[
                                        "form-message",
                                        /* :: */[
                                          "form-message-for-field-of-collection",
                                          /* :: */[
                                            "failure",
                                            /* [] */0
                                          ]
                                        ]
                                      ])
                                }, match$1[0]) : React.createElement("div", {
                                  className: Cn.make(/* :: */[
                                        "form-message",
                                        /* :: */[
                                          "form-message-for-field-of-collection",
                                          /* :: */[
                                            "success",
                                            /* [] */0
                                          ]
                                        ]
                                      ])
                                }, "✓");
                        } else {
                          tmp = null;
                        }
                        return React.createElement("div", {
                                    key: authorNameDomId,
                                    className: "form-row"
                                  }, React.createElement("label", {
                                        className: "label-lg",
                                        htmlFor: authorNameDomId
                                      }, "Name"), React.createElement("input", {
                                        id: authorNameDomId,
                                        disabled: form.submitting,
                                        type: "text",
                                        value: author.name,
                                        onBlur: (function (param) {
                                            return Curry._1(form.blurAuthorName, index);
                                          }),
                                        onChange: (function ($$event) {
                                            var value = $$event.target.value;
                                            return Curry._2(form.updateAuthorName, (function (input) {
                                                          return {
                                                                  title: input.title,
                                                                  authors: Belt_Array.mapWithIndex(form.input.authors, (function (idx, author) {
                                                                          if (idx !== index) {
                                                                            return author;
                                                                          } else {
                                                                            return {
                                                                                    name: value
                                                                                  };
                                                                          }
                                                                        }))
                                                                };
                                                        }), index);
                                          })
                                      }), React.createElement("button", {
                                        className: "control",
                                        type: "button",
                                        onClick: (function (param) {
                                            return Curry._1(form.removeAuthor, index);
                                          })
                                      }, "✕"), tmp);
                      })), React.createElement("div", {
                      className: "form-row"
                    }, React.createElement("button", {
                          className: Cn.make(/* :: */[
                                "secondary",
                                /* :: */[
                                  "push-lg",
                                  /* [] */0
                                ]
                              ]),
                          type: "button",
                          onClick: (function (param) {
                              return Curry._1(form.addAuthor, {
                                          name: ""
                                        });
                            })
                        }, "Add author")), React.createElement("div", {
                      className: "form-row"
                    }, React.createElement("button", {
                          className: Cn.make(/* :: */[
                                "primary",
                                /* :: */[
                                  "push-lg",
                                  /* [] */0
                                ]
                              ]),
                          disabled: form.submitting
                        }, form.submitting ? "Submitting..." : "Submit"), typeof match$4 === "number" && match$4 !== 0 ? React.createElement("div", {
                            className: Cn.make(/* :: */[
                                  "form-status",
                                  /* :: */[
                                    "success",
                                    /* [] */0
                                  ]
                                ])
                          }, "✓ Posted") : null)));
}

var make = BlogPostForm$1;

export {
  BlogPostForm ,
  initialInput ,
  make ,
  
}
/* Form Not a pure module */
