// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE


var takenEmail = "test@taken.email";

function validateEmail(value) {
  return new Promise((function (resolve, param) {
                console.log("Remote validation triggered");
                setTimeout((function (param) {
                        if (value !== takenEmail) {
                          console.log("Remote validation succeeded");
                          return resolve(true);
                        } else {
                          console.log("Remote validation failed");
                          return resolve(false);
                        }
                      }), 1500);
                return /* () */0;
              }));
}

export {
  takenEmail ,
  validateEmail ,
  
}
/* No side effect */
